const env =
  process.env.REACT_APP_ENVIRONMENT || process.env.NODE_ENV || 'development';

const STAGING_RESOURCES = {
  resumeWithGirlImg:
    'https://firebasestorage.googleapis.com/v0/b/resume-builder-d9cb3.appspot.com/o/resources%2Fimages%2Fsection_1.png?alt=media&token=eb1bf2fb-51dc-4a2d-ac74-3c3c7274848f',
  joyImg:
    'https://firebasestorage.googleapis.com/v0/b/resume-builder-d9cb3.appspot.com/o/resources%2Fimages%2Fjoy.svg?alt=media&token=977cfa3a-62c3-49d3-a239-3b13522b9a83',
  growthImg:
    'https://firebasestorage.googleapis.com/v0/b/resume-builder-d9cb3.appspot.com/o/resources%2Fimages%2Fgrowth.svg?alt=media&token=a4d2a0dc-924e-41ec-9a75-a47438e2a1c9',
  growthLeftDotsImg:
    'https://firebasestorage.googleapis.com/v0/b/resume-builder-d9cb3.appspot.com/o/resources%2Fimages%2Fleft_side_dot.png?alt=media&token=da8c5448-b1b6-41ce-bce6-d878dd826706',
  growthRightDotsImg:
    'https://firebasestorage.googleapis.com/v0/b/resume-builder-d9cb3.appspot.com/o/resources%2Fimages%2Fright_side_dots.png?alt=media&token=60f064d8-d99e-4bee-9d37-ffbe006a19f4',
  degreeDayImg:
    'https://firebasestorage.googleapis.com/v0/b/resume-builder-d9cb3.appspot.com/o/resources%2Fimages%2Fdegree_day.svg?alt=media&token=c70021d9-7f1c-4f0a-8875-59cc84aa3739',
  logoImg:
    'https://firebasestorage.googleapis.com/v0/b/resume-builder-d9cb3.appspot.com/o/resources%2Fimages%2FtransparentLogo.png?alt=media&token=50365273-f28c-4ba0-96b8-1b56b91d01a3',
};

const PRODUCTION_RESOURCES = {
  resumeWithGirlImg:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/resources%2Fimages%2Fsection_1.png?alt=media&token=8d1896af-093d-4d99-9bea-e33dfe89f434',
  joyImg:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/resources%2Fimages%2Fjoy.svg?alt=media&token=c861950d-3533-4f06-b9ed-dad82df4d35c',
  growthImg:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/resources%2Fimages%2Fgrowth.svg?alt=media&token=45b93d78-301b-4ecb-a4c6-ba8ccb4f61e5',
  growthLeftDotsImg:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/resources%2Fimages%2Fleft_side_dot.png?alt=media&token=5f0c444e-32ab-434b-bf62-8c6d6611ee4f',
  growthRightDotsImg:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/resources%2Fimages%2Fright_side_dots.png?alt=media&token=1602b064-fbdd-44c0-96d0-8348d3d3ce6e',
  degreeDayImg:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/resources%2Fimages%2Fdegree_day.svg?alt=media&token=b78c8daf-ecf5-4431-bd4a-5d8b1783ac78',
  logoImg:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/resources%2Fimages%2FtransparentLogo.png?alt=media&token=6b878486-9093-4ea6-8c24-de0ade2c9bc5',
};

const DEVELOPMENT_RESOURCES = {
  resumeWithGirlImg:
    'https://instaresume.io/cdn-cgi/image/quality=20,width=414,height=294/section_1.webp',
  joyImg: '/joy.svg',
  growthImg: '/growth.svg',
  growthLeftDotsImg: '/left_side_dot.png',
  growthRightDotsImg: '/right_side_dots.png',
  degreeDayImg: '/degree_day.svg',
  logoImg: '/transparentLogo1.webp',
};

const STAGING_TEMPLATES_PREVIEW_URL = {
  forest_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FForestPreview_100kb.png?alt=media&token=4b0acbc0-2399-4f5d-899b-3c94a61eaa71',
  forest_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FForestPreview_200kb.png?alt=media&token=efc99061-93d7-4716-b538-7d5a1fed4abe',
  forest_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FForestPreview_30kb.webp?alt=media&token=d7b8a746-782e-4f94-bb6d-ca47aa41c3fc',
  ocean_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FOceanPreview_100kb.png?alt=media&token=103a5b67-9cde-4295-944a-f3c623215fcf',
  ocean_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FOceanPreview_200kb.png?alt=media&token=0358e7e7-6127-4601-9ad0-af70a13e4d5f',
  ocean_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FOceanPreview_30kb.webp?alt=media&token=67f0faa6-0fce-4eb1-a714-dff218911e2d',
  sun_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FSunPreview_100kb.png?alt=media&token=9845359b-ea0b-4fa7-a660-1e8bf85087fe',
  sun_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FSunPreview_200kb.png?alt=media&token=a78ab054-fb27-494c-99d0-9bb66201fd3e',
  sun_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FSunPreview_30kb.webp?alt=media&token=2f340803-80bc-4db6-8676-af79ce021e9c',
  fire_100kb:
    'https://firebasestorage.googleapis.com/v0/b/resume-builder-d9cb3.appspot.com/o/builder%2Ftemplate%2Fpreview%2FFirePreview_100kb.png?alt=media&token=965bc6fe-6f86-415e-880a-d97f63f1ebc6',
  fire_200kb:
    'https://firebasestorage.googleapis.com/v0/b/resume-builder-d9cb3.appspot.com/o/builder%2Ftemplate%2Fpreview%2FFirePreview_200kb.png?alt=media&token=a9e2dac2-9177-40d4-a3f3-934c7ddbec4b',
  fire_30kb:
    'https://firebasestorage.googleapis.com/v0/b/resume-builder-d9cb3.appspot.com/o/builder%2Ftemplate%2Fpreview%2FFirePreview_30kb.webp?alt=media&token=f3cd48c0-377c-45cb-93bd-bf2680f08e89',
  breeze_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FBreezePreview_100kb.png?alt=media&token=4251f94e-8619-4b3f-aeb5-1787fe608dee',
  breeze_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FBreezePreview_200kb.png?alt=media&token=a53f7ba2-ff13-4cf0-9e5c-c8f191186896',
  breeze_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FBreezePreview_30kb.webp?alt=media&token=cdca96a3-35f7-43a6-ac8d-485ccc9b5aad',
  tree_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FTreePreview_100kb.png?alt=media&token=3db36e9c-106f-432a-9c0c-e6beaca660a2',
  tree_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FTreePreview_200kb.png?alt=media&token=30a85385-1a27-4d24-91a0-f1ca464eacc0',
  tree_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FTreePreview_30kb.webp?alt=media&token=7754f466-108e-473c-b9b2-83ad9f1e6371',
  sky_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FSkyPreview_100kb.png?alt=media&token=7dbd8569-c030-4d5e-9480-75e925397fd6',
  sky_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FSkyPreview_200kb.png?alt=media&token=904b0e54-fd58-47d4-b315-dc573772dc9c',
  sky_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FSkyPreview_30kb.webp?alt=media&token=628b41cf-b09b-477f-926c-110011a0fe30',
  ice_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FIcePreview_100kb.png?alt=media&token=e0a4a228-0b84-40d7-94aa-72aa0448ce2f',
  ice_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FIcePreview_200kb.png?alt=media&token=2427b45f-a57c-45c6-aa66-36961f55e3d9',
  ice_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FIcePreview_30kb.webp?alt=media&token=cbf892ec-a40f-4b5e-87a2-b73ea27186c2',

  water_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FWaterPreview_100kb.png?alt=media&token=f77c30b8-eb2a-4a07-a27a-8e5630d03f8c',
  water_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FWaterPreview_200kb.png?alt=media&token=ad85d2b2-6aa1-429e-81fc-0c0a97004549',
  water_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FWaterPreview_30kb.webp?alt=media&token=52f194e8-76e1-4928-b5f6-bd2f9d73d5d2',
  cloud_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FCloudPreview_100kb.png?alt=media&token=6b605f8d-297e-4c25-99c8-a0fee7e0615f',
  cloud_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FCloudPreview_200kb.png?alt=media&token=245bacea-9216-4e80-91d0-13cc4e49b717',
  cloud_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FCloudPreview_30kb.webp?alt=media&token=f34d9fe4-0367-4052-ba92-a8ba1700989f',
  light_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FLightPreview_100kb.png?alt=media&token=3c98ba24-cd17-4f7e-8118-0884b4a18db8',
  light_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FLightPreview_200kb.png?alt=media&token=7b623c02-bd1a-4098-be7d-4bfdabba1e72',
  light_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FLightPreview_30kb.webp?alt=media&token=921048e4-f28e-447b-baf7-1f0e00e7a27b',
  twilight_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FTwilightPreview_100kb.png?alt=media&token=3e960f38-e286-41f1-9cd2-dd9c3b343c8f',
  twilight_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FTwilightPreview_200kb.png?alt=media&token=1e2e4a66-e1ab-48b4-af3e-00a1982bfaee',
  twilight_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FTwilightPreview_30kb.webp?alt=media&token=13203aad-dbc0-4b05-88fa-d6281dae5efc',
  atsBasic_100kb:
    'https://firebasestorage.googleapis.com/v0/b/resume-builder-d9cb3.appspot.com/o/builder%2Ftemplate%2Fpreview%2FAtsBasicPreview_100kb.png?alt=media&token=6bb66794-787b-49eb-87bb-ecbbb233574c',
  atsBasic_200kb:
    'https://firebasestorage.googleapis.com/v0/b/resume-builder-d9cb3.appspot.com/o/builder%2Ftemplate%2Fpreview%2FAtsBasicPreview_200kb.png?alt=media&token=07d546a4-1ca3-401a-90ea-b596196bf342',
  atsBasic_30kb:
    'https://firebasestorage.googleapis.com/v0/b/resume-builder-d9cb3.appspot.com/o/builder%2Ftemplate%2Fpreview%2FAtsBasicPreview_30kb.webp?alt=media&token=79800965-57f2-417c-bf06-b14ed9605575',
  Marshmallow_100kb:
    'https://firebasestorage.googleapis.com/v0/b/resume-builder-d9cb3.appspot.com/o/builder%2Ftemplate%2Fpreview%2FMarshmallowPreview_100kb.png?alt=media&token=0d927d05-bd0d-4f51-b81d-8c7766028705',
  Marshmallow_200kb:
    'https://firebasestorage.googleapis.com/v0/b/resume-builder-d9cb3.appspot.com/o/builder%2Ftemplate%2Fpreview%2FMarshmallowPreview_200kb.png?alt=media&token=06d8b455-7f94-45a1-8b2b-df5dd2d38cf2',
  Marshmallow_30kb:
    'https://firebasestorage.googleapis.com/v0/b/resume-builder-d9cb3.appspot.com/o/builder%2Ftemplate%2Fpreview%2FMarshmallowPreview_30kb.webp?alt=media&token=766243f4-b2bf-473b-a52a-45fb10a333da',

  Tokyo_100kb:
    'https://firebasestorage.googleapis.com/v0/b/resume-builder-d9cb3.appspot.com/o/builder%2Ftemplate%2Fpreview%2FTokyoPreview_100kb.png?alt=media&token=b907a5c9-6934-45c1-8b61-08cbf58c2688',
  Tokyo_200kb:
    'https://firebasestorage.googleapis.com/v0/b/resume-builder-d9cb3.appspot.com/o/builder%2Ftemplate%2Fpreview%2FTokyoPreview_200kb.png?alt=media&token=9263733e-2f4d-454e-b886-95ce47756d4c',
  Tokyo_30kb:
    'https://firebasestorage.googleapis.com/v0/b/resume-builder-d9cb3.appspot.com/o/builder%2Ftemplate%2Fpreview%2FTokyoPreview_30kb.webp?alt=media&token=2c1250df-c732-4896-bfac-ae46e3e8fdfa',

  basic_cover_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FcoverPreview%2FBasic_Cover_30kb.webp?alt=media&token=5db7097b-c97a-438c-a6c7-de0410fb528f',
  basic_cover_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FcoverPreview%2FBasic_Cover_100kb.png?alt=media&token=28df9771-2829-42b6-a1e0-99133078e118',
  basic_cover_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FcoverPreview%2FBasic_Cover_200kb.png?alt=media&token=568992e5-970c-4fa6-bf3c-7b0501362c7c',
  advance_cover_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FcoverPreview%2FAdvance_Cover_30kb.webp?alt=media&token=0a39d8c9-3e4c-447a-bc82-4fff5e97fc65',
  advance_cover_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FcoverPreview%2FAdvance_Cover_100kb.png?alt=media&token=78a066ea-533a-4f70-a04c-7fc2e005c007',
  advance_cover_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FcoverPreview%2FAdvance_Cover_200kb.png?alt=media&token=3b780ea6-9726-407a-b7c3-fbdbe4ac7d0e',

  accountant_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FAccountant_200kb.jpeg?alt=media&token=8d440718-e4b2-41ee-ae02-8d59fb7ee13b',
  accountant_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FAccountant_100kb.webp?alt=media&token=5a488877-cff0-49a2-a1a7-502da0201a9f',
  accountant_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FAccountant_30kb.webp?alt=media&token=6b903feb-9820-40e2-bc06-cdbf2787ecd3',

  accountant2_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FAccountant-2_200kb.jpeg?alt=media&token=19a874f2-b175-438e-8cd6-0d2d75db8792',
  accountant2_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FAccountant-2_100kb.webp?alt=media&token=7aec98e9-692c-49e9-98a6-12fee5dc0d28',
  accountant2_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FAccountant-2_30kb.webp?alt=media&token=1bddfe1d-9a0a-4c39-806e-f6a2230043a7',

  accountantingManager_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FAccountingManager_200kb.jpeg?alt=media&token=4766888a-7913-4269-9beb-727844c7496d',
  accountantingManager_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FAccountingManager_100kb.webp?alt=media&token=fb48806b-117f-476e-9337-7660f0528161',
  accountantingManager_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FAccountingManager_30kb.webp?alt=media&token=736b3050-662c-45fe-a66e-7447e84aa216',

  dataAnalyst_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FDataAnalyst_200kb.jpeg?alt=media&token=562b5f58-04a5-4a27-aeca-d2277b44ddd9',
  dataAnalyst_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FDataAnalyst_100kb.webp?alt=media&token=c7fc4921-2a4b-4c9f-a461-e50300db1b13',
  dataAnalyst_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FDataAnalyst_30kb.webp?alt=media&token=b3f7f080-cddc-4f07-a963-8a1a3b0aeb22',

  dataEngineer_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FDataEngineer_200kb.jpeg?alt=media&token=fbd32974-8214-4dd7-9197-b47af2ae8ec5',
  dataEngineer_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FDataEngineer_100kb.webp?alt=media&token=1bdcb7bd-cd5f-4238-975f-64bf07e2b663',
  dataEngineer_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FDataEngineer_30kb.webp?alt=media&token=bc3e1541-ef63-44b4-ab52-439c6e0994cf',

  dataScientist_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FDataScientist_200kb.jpeg?alt=media&token=7ad7c14a-32da-4fa9-aa11-9fda95198723',
  dataScientist_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FDataScientist_100kb.webp?alt=media&token=b0d383dc-eca8-48c3-86da-a610e4c20d6b',
  dataScientist_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FDataScientist_30kb.webp?alt=media&token=815a9b66-570b-4505-bb4d-7c7344239856',

  fullStackDeveloper_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FFullStackDeveloper_200kb.jpeg?alt=media&token=fc73db7d-f972-456f-b32a-e41abad72e9d',
  fullStackDeveloper_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FFullStackDeveloper_100kb.webp?alt=media&token=de68ae3d-3fd8-4754-a886-6c9f78e147f4',
  fullStackDeveloper_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FFullStackDeveloper_30kb.webp?alt=media&token=6dce728c-c561-47bc-b4be-c65469a9df0e',

  fullStackWebDeveloper_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FFullStackWebDeveloper_200kb.jpeg?alt=media&token=4ffa0b97-a649-4f36-b199-3667f1bf8e0d',
  fullStackWebDeveloper_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FFullStackWebDeveloper_100kb.webp?alt=media&token=08744874-ae6b-4b39-b85c-ac9241893b35',
  fullStackWebDeveloper_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FFullStackWebDeveloper_30kb.webp?alt=media&token=a393a79a-5647-41d2-98c3-c1cfd93cac54',

  graphicDesigner_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FGraphicDesigner_200kb.jpeg?alt=media&token=c26c1918-64ef-4ccc-bd80-f1a93ba0ae18',
  graphicDesigner_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FGraphicDesigner_100kb.webp?alt=media&token=68b2baba-39ed-4272-8e35-3fe3509e2d20',
  graphicDesigner_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FGraphicDesigner_30kb.webp?alt=media&token=10fc7cd2-8283-47d0-8d61-ade01b4034a5',

  graphicDesigner2_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FGraphicDesigner-2_200kb.jpeg?alt=media&token=359545b0-b909-4d6a-925a-3c53f5efcc03',
  graphicDesigner2_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FGraphicDesigner-2_100kb.webp?alt=media&token=f795caef-3cb7-4a98-9a41-d8345cd133e9',
  graphicDesigner2_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FGraphicDesigner-2_30kb.webp?alt=media&token=29e1eadf-9ebd-4ed1-91fb-289fd46bb8f0',

  hotelManager_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FHotelManager_200kb.jpeg?alt=media&token=0706e0fb-edc0-4d37-ae4f-c3f92b21e251',
  hotelManager_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FHotelManager_100kb.webp?alt=media&token=dd88bf61-938b-4ec3-86c0-21b1e8aeb8e0',
  hotelManager_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FHotelManager_30kb.webp?alt=media&token=e897e067-2c9b-4f61-9cb8-f8abcd29981b',

  itProjectManager_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FITProjectManager_200kb.jpeg?alt=media&token=57decb7a-db79-4490-9626-e9ac8af0c41b',
  itProjectManager_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FITProjectManager_100kb.webp?alt=media&token=d32f26e4-cf1d-46d9-a5bd-f0dece5d22ce',
  itProjectManager_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FITProjectManager_30kb.webp?alt=media&token=fa55d934-ed34-4491-a4c6-dce3bf20fba9',

  investBankingAnalyst_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FInvestmentBankingAnalyst_200kb.jpeg?alt=media&token=ce9cfe73-5e11-4cec-8f9f-4d17580e048f',
  investBankingAnalyst_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FInvestmentBankingAnalyst_100kb.webp?alt=media&token=3bb989ba-a14c-4642-8fbe-d5560ea42b88',
  investBankingAnalyst_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FInvestmentBankingAnalyst_30kb.webp?alt=media&token=407e9386-ff25-4aea-bca2-d3f3dbc0f8b6',

  lawyer_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FLawyer_200kb.jpeg?alt=media&token=dc1f989e-431c-45e3-bd63-83043f52896d',
  lawyer_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FLawyer_100kb.webp?alt=media&token=32f34131-785c-4550-94f9-1b5ffdda1d5b',
  lawyer_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FLawyer_30kb.webp?alt=media&token=85ed8fd7-4796-4e2a-a523-ca82c73473bf',

  salesManager_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FSalesManager_200kb.jpeg?alt=media&token=eb6b0bb8-13bf-48a3-915a-edf2ca1e8d5d',
  salesManager_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FSalesManager_100kb.webp?alt=media&token=ffcc1124-4777-48eb-ad22-80e3d59179d4',
  salesManager_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FSalesManager_30kb.webp?alt=media&token=42e6e3cf-8d6e-4fc5-a3ee-a631d2509c84',

  salesRepresentative_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FSalesRepresentative_200kb.jpeg?alt=media&token=c6a89ec5-9ec2-4cb1-82e3-99788d80b37c',
  salesRepresentative_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FSalesRepresentative_100kb.webp?alt=media&token=69134525-0efb-4d40-9d5f-bcd2584d4b92',
  salesRepresentative_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FSalesRepresentative_30kb.webp?alt=media&token=4e6c1252-cb49-4819-b835-a62b89c8acae',

  seniorSoftwareEngineer_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FSeniorSoftwareEngineer_200kb.jpeg?alt=media&token=1927d163-1821-49fb-98bd-1d29626cc3d1',
  seniorSoftwareEngineer_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FSeniorSoftwareEngineer_100kb.webp?alt=media&token=e4568321-a70a-45bc-80d3-db33115ad2fd',
  seniorSoftwareEngineer_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FSeniorSoftwareEngineer_30kb.webp?alt=media&token=249cef9e-2f87-4de4-a0ce-5767e4018c66',

  teacher_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FTeacher_200kb.jpeg?alt=media&token=0f1296fc-970a-49d5-8171-22492cdb0566',
  teacher_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FTeacher_100kb.webp?alt=media&token=96a4f3b2-d316-4eb9-96f0-00ae47324d54',
  teacher_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FTeacher_30kb.webp?alt=media&token=cb4c81ac-0c93-4913-a282-a14f83274476',
};
const PRODUCTION_TEMPLATES_PREVIEW_URL = {
  forest_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FForestPreview_100kb.png?alt=media&token=4b0acbc0-2399-4f5d-899b-3c94a61eaa71',
  forest_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FForestPreview_200kb.png?alt=media&token=efc99061-93d7-4716-b538-7d5a1fed4abe',
  forest_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FForestPreview_30kb.webp?alt=media&token=d7b8a746-782e-4f94-bb6d-ca47aa41c3fc',
  ocean_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FOceanPreview_100kb.png?alt=media&token=103a5b67-9cde-4295-944a-f3c623215fcf',
  ocean_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FOceanPreview_200kb.png?alt=media&token=0358e7e7-6127-4601-9ad0-af70a13e4d5f',
  ocean_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FOceanPreview_30kb.webp?alt=media&token=67f0faa6-0fce-4eb1-a714-dff218911e2d',
  sun_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FSunPreview_100kb.png?alt=media&token=9845359b-ea0b-4fa7-a660-1e8bf85087fe',
  sun_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FSunPreview_200kb.png?alt=media&token=a78ab054-fb27-494c-99d0-9bb66201fd3e',
  sun_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FSunPreview_30kb.webp?alt=media&token=2f340803-80bc-4db6-8676-af79ce021e9c',
  fire_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FFirePreview_100kb.png?alt=media&token=ee24c5e8-bc19-4de9-b394-33643ca9734f',
  fire_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FFirePreview_200kb.png?alt=media&token=f2ef86dc-0181-4140-a13b-a2f31ced72e8',
  fire_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FFirePreview_30kb.webp?alt=media&token=99288a48-4826-4f7b-bd40-c9641f24692a',
  breeze_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FBreezePreview_100kb.png?alt=media&token=4251f94e-8619-4b3f-aeb5-1787fe608dee',
  breeze_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FBreezePreview_200kb.png?alt=media&token=a53f7ba2-ff13-4cf0-9e5c-c8f191186896',
  breeze_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FBreezePreview_30kb.webp?alt=media&token=cdca96a3-35f7-43a6-ac8d-485ccc9b5aad',
  tree_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FTreePreview_100kb.png?alt=media&token=3db36e9c-106f-432a-9c0c-e6beaca660a2',
  tree_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FTreePreview_200kb.png?alt=media&token=30a85385-1a27-4d24-91a0-f1ca464eacc0',
  tree_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FTreePreview_30kb.webp?alt=media&token=7754f466-108e-473c-b9b2-83ad9f1e6371',
  sky_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FSkyPreview_100kb.png?alt=media&token=7dbd8569-c030-4d5e-9480-75e925397fd6',
  sky_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FSkyPreview_200kb.png?alt=media&token=904b0e54-fd58-47d4-b315-dc573772dc9c',
  sky_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FSkyPreview_30kb.webp?alt=media&token=628b41cf-b09b-477f-926c-110011a0fe30',
  ice_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FIcePreview_100kb.png?alt=media&token=e0a4a228-0b84-40d7-94aa-72aa0448ce2f',
  ice_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FIcePreview_200kb.png?alt=media&token=2427b45f-a57c-45c6-aa66-36961f55e3d9',
  ice_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FIcePreview_30kb.webp?alt=media&token=cbf892ec-a40f-4b5e-87a2-b73ea27186c2',
  water_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FWaterPreview_100kb.png?alt=media&token=f77c30b8-eb2a-4a07-a27a-8e5630d03f8c',
  water_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FWaterPreview_200kb.png?alt=media&token=ad85d2b2-6aa1-429e-81fc-0c0a97004549',
  water_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FWaterPreview_30kb.webp?alt=media&token=52f194e8-76e1-4928-b5f6-bd2f9d73d5d2',
  cloud_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FCloudPreview_100kb.png?alt=media&token=6b605f8d-297e-4c25-99c8-a0fee7e0615f',
  cloud_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FCloudPreview_200kb.png?alt=media&token=245bacea-9216-4e80-91d0-13cc4e49b717',
  cloud_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FCloudPreview_30kb.webp?alt=media&token=f34d9fe4-0367-4052-ba92-a8ba1700989f',
  light_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FLightPreview_100kb.png?alt=media&token=3c98ba24-cd17-4f7e-8118-0884b4a18db8',
  light_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FLightPreview_200kb.png?alt=media&token=7b623c02-bd1a-4098-be7d-4bfdabba1e72',
  light_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FLightPreview_30kb.webp?alt=media&token=921048e4-f28e-447b-baf7-1f0e00e7a27b',
  twilight_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FTwilightPreview_100kb.png?alt=media&token=3e960f38-e286-41f1-9cd2-dd9c3b343c8f',
  twilight_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FTwilightPreview_200kb.png?alt=media&token=1e2e4a66-e1ab-48b4-af3e-00a1982bfaee',
  twilight_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FTwilightPreview_30kb.webp?alt=media&token=13203aad-dbc0-4b05-88fa-d6281dae5efc',
  atsBasic_100kb:
    'https://firebasestorage.googleapis.com/v0/b/resume-builder-d9cb3.appspot.com/o/builder%2Ftemplate%2Fpreview%2FAtsBasicPreview_100kb.png?alt=media&token=6bb66794-787b-49eb-87bb-ecbbb233574c',
  atsBasic_200kb:
    'https://firebasestorage.googleapis.com/v0/b/resume-builder-d9cb3.appspot.com/o/builder%2Ftemplate%2Fpreview%2FAtsBasicPreview_200kb.png?alt=media&token=07d546a4-1ca3-401a-90ea-b596196bf342',
  atsBasic_30kb:
    'https://firebasestorage.googleapis.com/v0/b/resume-builder-d9cb3.appspot.com/o/builder%2Ftemplate%2Fpreview%2FAtsBasicPreview_30kb.webp?alt=media&token=79800965-57f2-417c-bf06-b14ed9605575',
  Marshmallow_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FMarshmallowPreview_100kb.png?alt=media&token=8cc08760-5c53-4dda-9f11-e50785477d2c',
  Marshmallow_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FMarshmallowPreview_200kb.png?alt=media&token=c8b5ec50-8ca6-415d-b124-c65fdd3c33a1',
  Marshmallow_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FMarshmallowPreview_30kb.webp?alt=media&token=44550ddb-0474-47ef-8443-ac1f1d8565fe',

  Tokyo_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FTokyoPreview_100kb.png?alt=media&token=9b19ecd2-da16-48ce-bf01-d1b475ebc578',
  Tokyo_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FTokyoPreview_200kb.png?alt=media&token=7a5e6684-0183-423d-adc8-5113556d20b8',
  Tokyo_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2Fpreview%2FTokyoPreview_30kb.webp?alt=media&token=eea32ce5-d121-4163-8b71-b6a111551476',

  basic_cover_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FcoverPreview%2FBasic_Cover_30kb.webp?alt=media&token=5db7097b-c97a-438c-a6c7-de0410fb528f',
  basic_cover_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FcoverPreview%2FBasic_Cover_100kb.png?alt=media&token=28df9771-2829-42b6-a1e0-99133078e118',
  basic_cover_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FcoverPreview%2FBasic_Cover_200kb.png?alt=media&token=568992e5-970c-4fa6-bf3c-7b0501362c7c',
  advance_cover_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FcoverPreview%2FAdvance_Cover_30kb.webp?alt=media&token=0a39d8c9-3e4c-447a-bc82-4fff5e97fc65',
  advance_cover_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FcoverPreview%2FAdvance_Cover_100kb.png?alt=media&token=78a066ea-533a-4f70-a04c-7fc2e005c007',
  advance_cover_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FcoverPreview%2FAdvance_Cover_200kb.png?alt=media&token=3b780ea6-9726-407a-b7c3-fbdbe4ac7d0e',

  accountant_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FAccountant_200kb.jpeg?alt=media&token=8d440718-e4b2-41ee-ae02-8d59fb7ee13b',
  accountant_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FAccountant_100kb.webp?alt=media&token=5a488877-cff0-49a2-a1a7-502da0201a9f',
  accountant_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FAccountant_30kb.webp?alt=media&token=6b903feb-9820-40e2-bc06-cdbf2787ecd3',

  accountant2_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FAccountant-2_200kb.jpeg?alt=media&token=19a874f2-b175-438e-8cd6-0d2d75db8792',
  accountant2_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FAccountant-2_100kb.webp?alt=media&token=7aec98e9-692c-49e9-98a6-12fee5dc0d28',
  accountant2_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FAccountant-2_30kb.webp?alt=media&token=1bddfe1d-9a0a-4c39-806e-f6a2230043a7',

  accountantingManager_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FAccountingManager_200kb.jpeg?alt=media&token=4766888a-7913-4269-9beb-727844c7496d',
  accountantingManager_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FAccountingManager_100kb.webp?alt=media&token=fb48806b-117f-476e-9337-7660f0528161',
  accountantingManager_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FAccountingManager_30kb.webp?alt=media&token=736b3050-662c-45fe-a66e-7447e84aa216',

  dataAnalyst_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FDataAnalyst_200kb.jpeg?alt=media&token=562b5f58-04a5-4a27-aeca-d2277b44ddd9',
  dataAnalyst_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FDataAnalyst_100kb.webp?alt=media&token=c7fc4921-2a4b-4c9f-a461-e50300db1b13',
  dataAnalyst_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FDataAnalyst_30kb.webp?alt=media&token=b3f7f080-cddc-4f07-a963-8a1a3b0aeb22',

  dataEngineer_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FDataEngineer_200kb.jpeg?alt=media&token=fbd32974-8214-4dd7-9197-b47af2ae8ec5',
  dataEngineer_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FDataEngineer_100kb.webp?alt=media&token=1bdcb7bd-cd5f-4238-975f-64bf07e2b663',
  dataEngineer_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FDataEngineer_30kb.webp?alt=media&token=bc3e1541-ef63-44b4-ab52-439c6e0994cf',

  dataScientist_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FDataScientist_200kb.jpeg?alt=media&token=7ad7c14a-32da-4fa9-aa11-9fda95198723',
  dataScientist_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FDataScientist_100kb.webp?alt=media&token=b0d383dc-eca8-48c3-86da-a610e4c20d6b',
  dataScientist_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FDataScientist_30kb.webp?alt=media&token=815a9b66-570b-4505-bb4d-7c7344239856',

  fullStackDeveloper_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FFullStackDeveloper_200kb.jpeg?alt=media&token=fc73db7d-f972-456f-b32a-e41abad72e9d',
  fullStackDeveloper_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FFullStackDeveloper_100kb.webp?alt=media&token=de68ae3d-3fd8-4754-a886-6c9f78e147f4',
  fullStackDeveloper_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FFullStackDeveloper_30kb.webp?alt=media&token=6dce728c-c561-47bc-b4be-c65469a9df0e',

  fullStackWebDeveloper_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FFullStackWebDeveloper_200kb.jpeg?alt=media&token=4ffa0b97-a649-4f36-b199-3667f1bf8e0d',
  fullStackWebDeveloper_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FFullStackWebDeveloper_100kb.webp?alt=media&token=08744874-ae6b-4b39-b85c-ac9241893b35',
  fullStackWebDeveloper_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FFullStackWebDeveloper_30kb.webp?alt=media&token=a393a79a-5647-41d2-98c3-c1cfd93cac54',

  graphicDesigner_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FGraphicDesigner_200kb.jpeg?alt=media&token=c26c1918-64ef-4ccc-bd80-f1a93ba0ae18',
  graphicDesigner_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FGraphicDesigner_100kb.webp?alt=media&token=68b2baba-39ed-4272-8e35-3fe3509e2d20',
  graphicDesigner_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FGraphicDesigner_30kb.webp?alt=media&token=10fc7cd2-8283-47d0-8d61-ade01b4034a5',

  graphicDesigner2_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FGraphicDesigner-2_200kb.jpeg?alt=media&token=359545b0-b909-4d6a-925a-3c53f5efcc03',
  graphicDesigner2_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FGraphicDesigner-2_100kb.webp?alt=media&token=f795caef-3cb7-4a98-9a41-d8345cd133e9',
  graphicDesigner2_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FGraphicDesigner-2_30kb.webp?alt=media&token=29e1eadf-9ebd-4ed1-91fb-289fd46bb8f0',

  hotelManager_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FHotelManager_200kb.jpeg?alt=media&token=0706e0fb-edc0-4d37-ae4f-c3f92b21e251',
  hotelManager_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FHotelManager_100kb.webp?alt=media&token=dd88bf61-938b-4ec3-86c0-21b1e8aeb8e0',
  hotelManager_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FHotelManager_30kb.webp?alt=media&token=e897e067-2c9b-4f61-9cb8-f8abcd29981b',

  itProjectManager_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FITProjectManager_200kb.jpeg?alt=media&token=57decb7a-db79-4490-9626-e9ac8af0c41b',
  itProjectManager_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FITProjectManager_100kb.webp?alt=media&token=d32f26e4-cf1d-46d9-a5bd-f0dece5d22ce',
  itProjectManager_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FITProjectManager_30kb.webp?alt=media&token=fa55d934-ed34-4491-a4c6-dce3bf20fba9',

  investBankingAnalyst_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FInvestmentBankingAnalyst_200kb.jpeg?alt=media&token=ce9cfe73-5e11-4cec-8f9f-4d17580e048f',
  investBankingAnalyst_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FInvestmentBankingAnalyst_100kb.webp?alt=media&token=3bb989ba-a14c-4642-8fbe-d5560ea42b88',
  investBankingAnalyst_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FInvestmentBankingAnalyst_30kb.webp?alt=media&token=407e9386-ff25-4aea-bca2-d3f3dbc0f8b6',

  lawyer_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FLawyer_200kb.jpeg?alt=media&token=dc1f989e-431c-45e3-bd63-83043f52896d',
  lawyer_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FLawyer_100kb.webp?alt=media&token=32f34131-785c-4550-94f9-1b5ffdda1d5b',
  lawyer_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FLawyer_30kb.webp?alt=media&token=85ed8fd7-4796-4e2a-a523-ca82c73473bf',

  salesManager_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FSalesManager_200kb.jpeg?alt=media&token=eb6b0bb8-13bf-48a3-915a-edf2ca1e8d5d',
  salesManager_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FSalesManager_100kb.webp?alt=media&token=ffcc1124-4777-48eb-ad22-80e3d59179d4',
  salesManager_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FSalesManager_30kb.webp?alt=media&token=42e6e3cf-8d6e-4fc5-a3ee-a631d2509c84',

  salesRepresentative_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FSalesRepresentative_200kb.jpeg?alt=media&token=c6a89ec5-9ec2-4cb1-82e3-99788d80b37c',
  salesRepresentative_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FSalesRepresentative_100kb.webp?alt=media&token=69134525-0efb-4d40-9d5f-bcd2584d4b92',
  salesRepresentative_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FSalesRepresentative_30kb.webp?alt=media&token=4e6c1252-cb49-4819-b835-a62b89c8acae',

  seniorSoftwareEngineer_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FSeniorSoftwareEngineer_200kb.jpeg?alt=media&token=1927d163-1821-49fb-98bd-1d29626cc3d1',
  seniorSoftwareEngineer_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FSeniorSoftwareEngineer_100kb.webp?alt=media&token=e4568321-a70a-45bc-80d3-db33115ad2fd',
  seniorSoftwareEngineer_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FSeniorSoftwareEngineer_30kb.webp?alt=media&token=249cef9e-2f87-4de4-a0ce-5767e4018c66',

  teacher_200kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FTeacher_200kb.jpeg?alt=media&token=0f1296fc-970a-49d5-8171-22492cdb0566',
  teacher_100kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FTeacher_100kb.webp?alt=media&token=96a4f3b2-d316-4eb9-96f0-00ae47324d54',
  teacher_30kb:
    'https://firebasestorage.googleapis.com/v0/b/instaresume-backend.appspot.com/o/builder%2Ftemplate%2FprefilledResumePreview%2FTeacher_30kb.webp?alt=media&token=cb4c81ac-0c93-4913-a282-a14f83274476',
};

const resources =
  env === 'production'
    ? DEVELOPMENT_RESOURCES
    : env === 'staging'
    ? STAGING_RESOURCES
    : DEVELOPMENT_RESOURCES;

const templatesPreview =
  env === 'production'
    ? PRODUCTION_TEMPLATES_PREVIEW_URL
    : env === 'staging'
    ? STAGING_TEMPLATES_PREVIEW_URL
    : STAGING_TEMPLATES_PREVIEW_URL;

export { resources as ImageResources };
export { templatesPreview as TemplatesPreviewURLs };

export const DEMO_EDITOR_KEYS = {
  DEMO_DATA: 'insta_resume:demo_data',
  DEMO_META: 'insta_resume:demo_meta',
  TRUST_PILOT_REQUEST: 'insta_resume:tp_review_request',
};

export const BUY_ME_COFFEE_IMAGE =
  'https://img.buymeacoffee.com/button-api/?text=Buy me a coffee&emoji=&slug=InstaResume&button_colour=FFDD00&font_colour=000000&font_family=Cookie&outline_colour=000000&coffee_colour=ffffff&date=${new Date().toISOString()}';

export const DEFAULT_RU_CREDITS = 1;
export const DEFAULT_AI_CREDITS = 3;

export const WA_BASE_URL = 'https://wa.me';
export const WA_NUMBER = '917986481201';
export const USER_LOCATION = 'userLocation';

const PRODUCTION_BASE_URL = 'https://instaresume.io';
const STAGING_BASE_URL = 'https://staging.instaresume.io';
export const BASE_URL =
  env === 'production'
    ? PRODUCTION_BASE_URL
    : env === 'staging'
    ? STAGING_BASE_URL
    : PRODUCTION_BASE_URL;

export const JD_DEFAULT_DATA_KEY = 'insta_resume:default_jd_data';
