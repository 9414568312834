export const createNewUser = async function (user) {
  const { doc, setDoc, getFirestore } = await import('firebase/firestore');
  const db = getFirestore();
  const cityRef = doc(db, 'users', user.uid);
  let firstName = user.firstName;
  let lastName = user.lastName;
  if ((!firstName || !lastName) && user.displayName) {
    const [first, last] = user.displayName.split(' ');
    firstName = first;
    lastName = last;
  }
  setDoc(
    cityRef,
    {
      capital: true,
      displayName: user.displayName,
      uid: user.uid,
      photoURL: user.photoURL,
      providers: user.providerData,
      emailVerified: user.emailVerified,
      isAnonymous: user.isAnonymous,
      email: user.email,
      firstName,
      lastName,
    },
    { merge: true },
  );
};

export const getUserData = async function (user) {
  const { doc, getDoc, getFirestore } = await import('firebase/firestore');
  const db = getFirestore();
  const docRef = doc(db, 'users', user.uid);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const docData = docSnap.data();

    return docData;
  }
  return false;
};

export const getUserPaymentInfo = async function (
  uid,
  builder,
  paymentType,
  status,
) {
  const { getDocs, getFirestore, where, query, collection } = await import(
    'firebase/firestore'
  );
  const db = getFirestore();
  try {
    const q = query(
      collection(db, builder),
      where('userId', '==', uid),
      where('paymentType', '==', paymentType),
      status && where('status', '==', status),
    );
    const querySnapShot = await getDocs(q);
    const paymentInfo = querySnapShot.docs.map((doc) => {
      return { ...doc.data(), id: doc.id };
    });
    return paymentInfo;
  } catch (error) {
    console.error('Error fetching payment info:', error);
    throw error;
  }
};

export const updateUserData = async function (uid, updatedUserData = {}) {
  const { doc, setDoc, getFirestore } = await import('firebase/firestore');
  const db = getFirestore();
  const docRef = doc(db, 'users', uid);

  setDoc(docRef, updatedUserData, { merge: true });
};
