import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from 'base-shell/lib/providers/Auth';
import { useConfig } from 'base-shell/lib/providers/Config';

function AuthorizedRouteV2({ children }) {
  const { appConfig } = useConfig();
  const { auth: authConfig } = appConfig || {};
  const { signInURL = '/signin' } = authConfig || {};
  const { auth } = useAuth();
  const location = useLocation();

  if (auth.isAuthenticated) {
    return children;
  } else {
    let from = location.pathname;
    if (location.search) {
      from = `${from}${location.search}`;
    }
    return (
      <Navigate
        //to={`${signInURL}?from=${location.pathname}`}
        to={{
          pathname: signInURL,
          search: `from=${from}`,
        }}
        state={{ location }}
        replace
      />
    );
  }
}

export default AuthorizedRouteV2;
